'use client';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  return (
    <div>
      <h2>Something went wrong!</h2>
      <pre>{JSON.stringify(error, null, 2)}</pre>
      {/* @ts-expect-error */}
      {error?.digest === 'NEXT_NOT_FOUND' ? '404' : ':('}
      <button onClick={() => reset()}>Try again</button>
    </div>
  );
}
